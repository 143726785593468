export class ValidationPatterns {

  /**
   * Pattern for email validation
   */
  public static readonly email = /^(([^<>()\[\]\\.,'`"|!%=+?&*^$#/;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  /**
   * Pattern for password validation
   */
  public static readonly password = /.{8,}/;

  /**
   * Pattern for phone number validation
   */
  public  static readonly phoneNumber = /(^[+]?[0-9]+)$/;

  /**
   * Pattern for cvv validation
   */
  public static readonly cvv = /^[0-9]{3,4}$/;

  /**
   * Pattern for creditCard validation
   */
  public static readonly creditCard = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

  /**
   * Pattern for date of birth validation
   */
  public static readonly dateOfBirth = /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19(18|19|[2-9][0-9])|20\d\d)$/;

  /**
   * Pattern for controls which don't allow only whitespaces
   */
  public static readonly noOnlyWhiteSpaces = /.*\S.*/;

  /**
   * Pattern only letters
   */
  public static readonly letters = /^[一-龠ぁ-ゔァ-ヴー A-Za-z wàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\-]+$/;

  /**
   * Pattern only letters
   */
  public static readonly lettersWithSpaces = /^[一-龠ぁ-ゔァ-ヴー A-Za-z wàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\s]*$/;

  /**
   * Pattern only letters and numbers
   */
  public static readonly lettersAndNumbers = /^[a-zA-Z0-9]+$/;

  /**
   * Pattern with no spaces and symbols
   */
  public static readonly noSpacesAndSymbols = /^[^\W_]+$/;

  /**
   * Pattern for country code, which consists of 2 letters
   */
  public static readonly twoLetters = /^[a-z]{2}$/i;

  /**
   * Pattern for canadian postal code
   */
  public static readonly canadianPostalCode = /(([A-Z][0-9][A-Z])|([a-z][0-9][a-z])) (([0-9][A-Z][0-9])|([0-9][a-z][0-9]))/;

  /**
   * Pattern for all languages letters with spaces with hyphen
   */
  public static readonly onlyLettersAndSpacesAndHyphenBracesPoint = /^[\u0600-\u06FF ぁ-ゔァ-ヴーA-Za-zwàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð(.)'`\s-]+$/;

  /**
   * Pattern letters and special symbols
   */
  public static readonly lettersAndSpecialSymbols = /^[$&+,`~{}\]\[_€£:;=?@#/\/| '\\<>.^*()%![\u0600-\u06FF ぁ-ゔァ-ヴー A-Za-z wàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð'-.\-]+$/;

  /**
   * Pattern for validate spaces at the start and end
   */
  public static readonly noSpaceAtStart = /^[^\s]+(\s+[^\s]+)*$/;
}
